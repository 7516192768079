import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  ImageCollection,
  Reviews,
  CustomerReviewModal,
} from "@bluefin/components";
import { Header, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"about-page"}
        >
          <Content className={"about-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>About Us</Header>
            </Content.Markup>
            <Content.Markup width={16} isMarkdown={false}>
              <div className={"image-container"}>
                <Image
                  size={"large"}
                  size={"large"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/weeyumsphillystyle/aboutpage.jpg"
                  }
                />
              </div>
              <p>
                Opened in May 2000, Weeyums is an award winning, authentic
                cheesesteak and hoagie eatery with it's main location in the
                heart of Stone Mountain, Georgia and additional location in
                Atlanta, Georgia. We pride ourselves on the quality of our food,
                our custom sandwiches. Our food is sourced from top quality
                ingredients, while utilizing our own brand of meat. In addition
                to our top-of-the-line ingredients, we try our hardest to keep
                processed foods to a minimum.
              </p>
              <p>
                Inspired by the lack of authentic cheesesteak restaurants in
                Atlanta, Owner William Dawson realized the possible impact
                Weeyums could have on the surrounding area. Despite the fact
                that we did not use advertising, our loyal customers assisted us
                in spreading the word about our new establishment. With the
                ability to create custom sandwiches, while incorporating quality
                ingredients, Weeyums plays a major role in enhancing your taste
                buds as well as the overall community.
              </p>
              <p>
                Although Weeyums is an eatery, we are recognized as a staple
                amongst the community and was recently names one of Georgia's
                "100 Plates That Locals Love", by Georgia Department of Economic
                Development’s Tourism Division.. Throughout our early years in
                business, Weeyums immediately gained recognition as being a
                positive impact on the surrounding area. Initially opening to
                become a neighborhood shop and to bond with the community, our
                business has done so much more. Weeyums is not only a place to
                purchase a one-of-a-kind cheesesteak, but it is also a place
                filled with opportunities for today’s youth. Through countless
                job opportunities, Weeyums is a way to keep neighborhood kids on
                the right track, providing them with a sense of ownership, and
                instilling a set of skills that are applicable in everyday life.
                Without the help of the neighborhood and our earliest
                supporters, Weeyums would not be the thriving business that it
                is today. We are looking to expand the business; however, we
                only wish to do so if we can duplicate the same level of service
                and reputation in the community. Although everything WEE make is
                good, our level of customer service and overall sense of
                community is even better!
              </p>
            </Content.Markup>
          </Content>
          <ImageCollection
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "ImageCollection",
            })}
            as={"gallery"}
            centered={true}
            cutUnevenRows={true}
            smartImageGrouping={false}
            disable={"none"}
            header={""}
            numberOfRowDisplayItems={3}
          />
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={"restaurant"}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        author
        text
        link
      }
      _id
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
